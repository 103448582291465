@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #4a00e1 #e2e8f0;
    }
    
    /* Handle */
    .custom-scrollbar::-webkit-scrollbar {
      width: 10px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: #4a00e1;
      border-radius: 6px;
    }
    
    
    /* Track */
    .custom-scrollbar::-webkit-scrollbar-track {
      background-color: #e2e8f0;
      border-radius: 6px;
    }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

h1, h2, h3, h4, h5, p, a, button, input{
      font-family: 'Montserrat', sans-serif;
}
a, button{
      font-weight: 600;
}
h1, h2, h3, h4, h5, p{
      font-weight: 500;
}

