.connect-button .connect {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.connect-button .user {
      border-color: rgb(27, 25, 153);
      border-color: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.connect-button .user .user-icon {
      color: rgb(27, 25, 153);
      color: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

footer .footer-container {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.bg-image {
      background-image: url(images/background-main.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.background-profile {
      background-image: url(images/background-profile.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.how-it-works-bg {
      background-image: url(images/background-middle.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.background-staking {
      background-image: url(images/background-seed.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.background-airdrop {
      background-image: url(images/background-airdrop.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.background-vesting {
      background-image: url(images/background-vesting.jpg);
      background-size: cover;
      /* or "contain" depending on your preference */
      background-position: center;
      /* Optional: Adjust the position as needed */
      background-repeat: no-repeat;
}

.left-right-container {
      box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      -webkit-box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      -moz-box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      border: none;
}

.airdroplist-item,
.staking-inside-container2 {
      box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.192);
}

.right-part .amount {
      background: rgb(238, 182, 23);
      background: linear-gradient(45deg, rgb(219, 165, 14) 0%, rgba(220, 93, 74, 1) 100%, rgba(129, 105, 241, 1) 100%);
}

.right-part .balance,
.claim-button,
.balance {
      background: rgb(70, 198, 69);
      background: linear-gradient(45deg, rgb(33, 170, 33) 0%, rgb(3, 94, 22) 100%);
}

.owner .address {
      color: rgb(20, 18, 121);
      color: linear-gradient(45deg, rgb(20, 18, 121) 0%, rgb(100, 69, 235) 55%, rgb(132, 104, 243) 85%, rgb(128, 65, 252) 100%);
}

.profile-navbar {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.bottom-part button,
.stake-token {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.profile-part .wallet-address {
      background: rgb(238, 182, 23);
      background: linear-gradient(45deg, rgb(219, 165, 14) 0%, rgba(220, 93, 74, 1) 100%, rgba(129, 105, 241, 1) 100%);
}

.token-info-part,
.profile-info-part,
.transfer-table,
.claim-check,
.full-part {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.blue-bg {
      background: rgb(27, 25, 153);
      background: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.blue-text {
      color: rgb(27, 25, 153);
      color: linear-gradient(45deg, rgba(27, 25, 153, 1) 0%, rgb(60, 38, 158) 55%, rgb(77, 43, 211) 85%, rgb(99, 31, 233) 100%);
}

.shadow {
      box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      -webkit-box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      -moz-box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.7);
      border: none;
}

.shadow-soft {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.mobile-links-container {
      overflow: hidden;
      height: 0px !important;
      transition: height 0.3s ease-in-out;
}

.mobile-links-container.active {
      height: 350px !important;
}

.dropdown-arrow {
      transition: transform 0.25s ease-in-out;
}

.slider-inside {
      transition: transform 0.25s ease-in-out;
}

.centered-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      mix-blend-mode: difference;
      color: rgb(251, 255, 0);
}

.centered {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
}

.centered-x {
      left: 50%;
      transform: translate(-50%, -50%);
}

.text-copied.active {
      bottom: 100px;
      transition: all 0.2s ease-in-out;
}

.text-copied {
      bottom: -80px;
      transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 600px) {
      .text-copied.active {
            bottom: 100px;
            transition: all 0.2s ease-in-out;
      }

      .text-copied {
            bottom: -80px;
            transition: all 0.2s ease-in-out;
      }
}

.no-scroll {
      overflow: hidden;
}

.MuiChartsLegend-root {
      display: none;
}

.shadow-soft3 {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 100px 0px;
}

.shadow-soft2 {
      box-shadow: 0px -15px 100px 0px rgba(0, 0, 0, 0.1);
}

.alert{
      color: red;
      margin-top: 20px;
}